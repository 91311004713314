import './App.css';
import { NavBar, Home, Skills, Projects, Footer } from "./Components";
import { useState } from 'react';

function App() {
  const [darkMode, setDarkMode] = useState(true);

  return (
    <div>
      <NavBar darkMode={darkMode} darkModeToggle={setDarkMode} />
      <Home darkMode={darkMode} />
      <Skills darkMode={darkMode} />
      <Projects darkMode={darkMode} />
      <Footer darkMode={darkMode} />
    </div>
  );
}

export default App;
