import "./NavBar.css";
import { LightIcon, DarkIcon } from "../Icons/Icons.js";
import { GrLinkedin } from "react-icons/gr";
import { BsGithub } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

import resume from "../../Assets/resume.pdf";
import { useEffect } from "react";

const NavBar = (props) => {

  let navClass = "navbar navbar-expand-md fixed-top ";

  if (props.darkMode) {
    navClass += "navbar-dark ";
  }

  useEffect(() => {

    const rootNavbar = document.getElementById("root-navbar");
    const collapseDiv = document.getElementById('navbarNav');

    collapseDiv.addEventListener('show.bs.collapse', function () {
      rootNavbar.classList.add("navbar-dark", "bg-dark");
      collapseDiv.dataset.collapse = "true";
    });
    collapseDiv.addEventListener('hide.bs.collapse', function () {
      //console.log("Inside event listener", rootNavbar.dataset.dark);
      if (rootNavbar.dataset.dark === "true")
        rootNavbar.classList.remove("bg-dark");
      else
        rootNavbar.classList.remove("bg-dark", "navbar-dark");

      collapseDiv.dataset.collapse = "false";
    });
  }, []);

  useEffect(() => {
    const rootNavbar = document.getElementById("root-navbar");
    const collapseDiv = document.getElementById('navbarNav');

    if (collapseDiv.dataset.collapse === "true") {
      rootNavbar.classList.add("navbar-dark", "bg-dark");
    }

  }, [props.darkMode]);

  return (
    <nav className={navClass} id="root-navbar" data-dark={`${props.darkMode}`}>
      <div className="container">
        <a className="navbar-brand" href="/">
          <h1 className="gradient-text brand-text">Sid426</h1>
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav" data-collapse="false">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item mx-2">
              <a className="nav-link" aria-current="page" href="#home">Home</a>
            </li>
            <li className="nav-item mx-2">
              <a className="nav-link" href="#skills">Skills</a>
            </li>
            <li className="nav-item mx-2">
              <a className="nav-link" href="#projects">Projects</a>
            </li>
            <li className="nav-item mx-2">
              <a className="nav-link" href={resume} target="_blank" rel="noopener noreferrer" >Resume</a>
            </li>
            <li className="nav-item mx-2">
              <a className="nav-link" href="https://github.com/sidhartha426" target="_blank" rel="noopener noreferrer" ><BsGithub /></a>
            </li>
            <li className="nav-item mx-2">
              <a className="nav-link" href="https://www.linkedin.com/in/sidhartha426" target="_blank" rel="noopener noreferrer"><GrLinkedin /></a>
            </li>
            <li className="nav-item mx-2">
              <a className="nav-link" href="mailto:contact@sid426.dev" target="_blank" rel="noopener noreferrer"><MdEmail /></a>
            </li>
          </ul>
          <span className="navbar-text mx-2" onClick={() => { props.darkModeToggle((prev) => !prev); }}>
            {props.darkMode ? <LightIcon /> : <DarkIcon />}
          </span>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;