import { BsGithub } from "react-icons/bs";
import { MdOndemandVideo } from "react-icons/md";
import { FiPlayCircle } from "react-icons/fi";

const projects = [
  {
    name: "Smart Debrid",

    desc: ["Smart Debrid is a powerful command line tool to fetch torrents from torrent network and files from file hosting sites using debrid-link.com API.",

      "It can effectively manage data about thousands of torrent and file links also generate scripts to download them in command line using aria2c from debridLink servers.",

      "It can also handle the Oauth Authentication work flow for a user.",

      "Build using raw Node JS and with just a single npm dependency."

    ],
    links: [{
      icon: <BsGithub />,
      text: "Source Code",
      href: "https://github.com/sidhartha426/Smart-Debrid"
    },
    {
      icon: <MdOndemandVideo />,
      text: "Video 1",
      href: "https://www.loom.com/share/7376563cb9a14aa6bd7aa239acdb7021"
    },
    {
      icon: <MdOndemandVideo />,
      text: "Video 2",
      href: "https://www.loom.com/share/7385fea12ba64a148350080e59765234"
    }
    ]
  }
  ,

  {
    name: "QKart",

    desc: ["QKart is a full-stack E-commerce App built with MERN Stack.",

      "It is a single page application built with React and React Router.",

      "It can handle user authentication and save userdata using localstorage.",

      "Backend build with Express.js and Database used MongoDB."

    ],
    links: [
    {
      icon: <MdOndemandVideo />,
      text: "Video",
      href: "https://www.loom.com/share/373b9036cc9c42dfba5811a721936944"
    }
    ]
  }

  ,

  {
    name: "Movie Stream",

    desc: ["Movie Stream is a powerful video server build using Node JS.",

      "It serves your local MP4 video files over HTTP.",

      "It also serves HTML front-end so anyone in your network can view it in the browser.",

      "Serves large video files to multiple clients using minimal system resources using Node JS Streams.",

      "Build using raw Node JS without any npm dependencies."],
    links: [{
      icon: <BsGithub />,
      text: "Source Code",
      href: "https://github.com/sidhartha426/MovieStream"
    },
    {
      icon: <MdOndemandVideo />,
      text: "Video",
      href: "https://www.loom.com/share/5e32247d946b42ad813eab1a834c3259"
    },
    ]
  }

  ,

  {
    name: "Permutations and Combinations Generator",

    desc: ["Generates Permutations and Combinations for a given Symbol Set.",

      "Generates all types of Permutaions and Combinations and gives option to download generated results in a text file.",

      "Calculates total number of permutations or combinations before generating.",

      "Provides a maximum limit on the number of results so that it can generate a limited number of results from a very large pool of results.",

    ],
    links: [{
      icon: <BsGithub />,
      text: "Source Code",
      href: "https://github.com/sidhartha426/Permutations-and-Combinations-Generator"
    },
    {
      icon: <FiPlayCircle />,
      text: "Try it",
      href: "https://pandc.sid426.dev"
    }
    ]
  }

];

export default projects;
