import "./Skills.css";
import skillsData from "./data.js";
import { useEffect, useState } from "react";

const skillLen = skillsData.length;
const randInt = () => Math.floor(Math.random() * skillLen);
const skillSet = new Set();


const SkillCard = (props) => {
  return (
    <div className="col-6 col-md-3 col-lg-2">
      <div className="skill-card">
        <img src={props.icon} className="skill-image" alt={props.name}></img>
        <div>
          {props.name}
        </div>
      </div>
    </div>
  );
}

const Skills = (props) => {

  const [order, setOrder] = useState(new Array(skillLen).fill(0).map((n, i) => i));

  useEffect(() => {
    const id = setInterval(() => {
      //console.log("",skillSet);
      skillSet.clear();
      while (skillSet.size !== skillLen) {
        skillSet.add(randInt());
      }
      //console.log(skillSet);
      setOrder(Array.from(skillSet));

      return () => { clearInterval(id) };
    }, 30000);
  }, []);

  let skillCardArray = new Array(skillLen);

  for (let i = 0; i < skillLen; i += 1) {

    const ind = order[i], { icon, name } = skillsData[i];

    //console.log(name,i);

    skillCardArray[ind] = <SkillCard icon={icon} name={name} key={i} />;
  }

  return (
    <section id="skills" className={props.darkMode ? "skills-dark" : "skills-light"}>
      <div className="skill-header-container"><h1 className="skill-header text-center">My Skills</h1></div>
      <div className="container">

        <div className="row align-items-center">
          {
            skillCardArray
          }
        </div>
      </div>

    </section>
  );
}

export default Skills;
