import reactIcon from "../../Assets/Icons/react.png";
import cssIcon from "../../Assets/Icons/css.png";
import htmlIcon from "../../Assets/Icons/html.png";
import jsIcon from "../../Assets/Icons/js.png";


import nodeIcon from "../../Assets/Icons/node.png";
import expressIcon from "../../Assets/Icons/express.png";
import mongooseIcon from "../../Assets/Icons/mongoose.png";
import mongodbIcon from "../../Assets/Icons/mongodb.png";

import linuxIcon from "../../Assets/Icons/linux.png";
import gitIcon from "../../Assets/Icons/git.png";
import cIcon from "../../Assets/Icons/clogo.png";
import pythonIcon from "../../Assets/Icons/python.png";


const skillsData = [

  {
    icon: nodeIcon,
    name: "Node JS"
  },
  {
    icon: expressIcon,
    name: "Express JS"
  },
  {
    icon: mongodbIcon,
    name: "MongoDB"
  },
  {
    icon: mongooseIcon,
    name: "Mongoose"
  },



  {
    icon: htmlIcon,
    name: "HTML"
  },
  {
    icon: cssIcon,
    name: "CSS"
  },
  {
    icon: jsIcon,
    name: "JavaScript"
  },
  {
    icon: reactIcon,
    name: "React"
  },


  {
    icon: pythonIcon,
    name: "Python"
  },
  
  {
    icon: gitIcon,
    name: "Git"
  },    
  {
    icon: linuxIcon,
    name: "Linux"
  },  
  {
    icon: cIcon,
    name: "C"
  }
];

export default skillsData;
