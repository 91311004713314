import "./Footer.css";

const Footer = (props) => {
  return (
    <section id="footer">
      <div className={`card text-center ${props.darkMode ? "footer-dark " : "footer-light "}`}>
        <div className="card-footer text-muted">
          Made with ❤️ and Passsion by Sidhartha Sankar © 2023
        </div>
      </div>
    </section>
  );
}

export default Footer;