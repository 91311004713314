import "./Projects.css";
import projects from "./data.js";

const ProjectLink = (props) => {
  return (
    <a href={props.href} className={`btn ${props.darkMode ? "btn-dark" : "btn-light"}` } target="_blank" rel="noopener noreferrer">
      <p> {props.icon} {props.text} </p>
    </a>
  );
}

const ProjectCard = (props) => {

  const desc = new Array(3 * props.desc.length - 2);

  for (let i = 0; i < desc.length; i += 3) {
    desc[i] = props.desc[i / 3];
  }

  for (let i = 1; i < desc.length; i += 3) {
    desc[i] = <br />;
    desc[i + 1] = <br />
  }

  return (
    <div className="col-12 col-md-6">
      <div className="project-card">
        < div className={`tagline  ${props.darkMode ? "tagline-dark" : "tagline-light"}`}>
          {props.name}
        </div>

        <div className="card-body">
          <p className="card-text project-desc">
            {desc}
          </p>
          <div className="project-links">
            {
              props.links.map(({ icon, text, href }, i) => {
                return (
                  <ProjectLink key={i} icon={icon} text={text} href={href} darkMode={props.darkMode} />
                );
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}


const Projects = (props) => {
  return (
    <section id="projects" className={props.darkMode ? "projects-dark" : "projects-light"}>
      <div className="skill-header-container"><h1 className="skill-header text-center">My Projects</h1></div>
      <div className="container">
        <div class="row align-items-start">
          {
            projects.map(({ name, desc, links }, i) => {
              return (
                <ProjectCard key={i} darkMode={props.darkMode} name={name} desc={desc} links={links} />
              );
            })
          }
        </div>
      </div>
    </section >
  );
}



export default Projects;

