import { useEffect, useState } from "react";
import "./Home.css";
import boyImage from "../../Assets/Icons/boy.png";

const words = ["Full-Stack Dev", "CS Enthusiast", "DSA Ninja", "Happy Soul"];

const Home = (props) => {

  const [currentWord, setCurrentWord] = useState(0);
  const [currentIndex, setcurrentIndex] = useState(1);
  const [isDeleting, setDelting] = useState(false);
  const [text, setText] = useState("");

  let word = words[currentWord];

  const updateText = () => {

    //console.log(currentIndex, isDeleting, text, currentWord);

    if (!isDeleting && currentIndex <= word.length) {
      setText(word.substring(0, currentIndex));
      setcurrentIndex((prev) => prev + 1);
    }
    else if (!isDeleting && currentIndex > word.length) {
      setDelting((prev) => !prev);
      setcurrentIndex((prev) => prev - 1);
    }
    else if (isDeleting && currentIndex > 0) {
      setText(word.substring(0, currentIndex));
      setcurrentIndex((prev) => prev - 1);
    }
    else if (isDeleting && currentIndex === 0) {
      setCurrentWord((prev) => (prev + 1) % words.length);
      setcurrentIndex((prev) => prev + 1);
      setDelting((prev) => !prev);
    }
  }

  useEffect(() => {
    setTimeout(updateText, 220);
  }, [currentIndex]);

  let bannerClass = "banner ", tagLineClass = "tagline ";
  if (props.darkMode) {
    bannerClass += "banner-dark";
    tagLineClass += "tagline-dark";
  }

  else {
    bannerClass += "banner-light";
    tagLineClass += "tagline-light";
  }

  return (
    <section id="home" className={bannerClass} >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 col-xl-7">
            <span className={tagLineClass}>Welcome to my Portfolio</span>
            <h1>{`Hi I'm Sidhartha `} <br /> {text} </h1>
            <p>
              A <strong> self-taught passionate </strong> full-stack developer who
              also has a deep interest in core Computer Science.
              <br />
              We can talk about Theoretical Computer Science & DSA for hours.
              <br />
              I have a dream to complete all volumes of <strong>The Art of Computer Programming</strong> by <strong>Donald Knuth</strong>.
            </p>
          </div>
          <div className="col-12 col-md-6 col-xl-5">
            <div>
              <img src={boyImage} alt="An Astronaut Boy"/>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}

export default Home;
